<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">

		<a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{ span: 12, offset: 0 }" :xl="{ span: 6, offset: 2 }" class="col-form">
				<h1 class="mb-15">登录</h1>
				<h5 hidden class="font-regular text-muted">Enter your email and password to sign in</h5>

				<!-- Sign In Form -->
				<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit"
					:hideRequiredMark="true">
					<a-form-item class="mb-10" label="账号" :colon="false">
						<a-input v-decorator="[
							'account',
							{ rules: [{ required: true, message: 'Please input your Email/Phone!' }] },
						]" placeholder="邮箱/手机号" />
					</a-form-item>
					<a-form-item class="mb-5" label="密码" :colon="false">
						<a-input v-decorator="[
							'password',
							{ rules: [{ required: true, message: 'Please input your password!' }] },
						]" type="password" placeholder="密码" />
					</a-form-item>
					<a-form-item class="mb-5" label="验证码" :colon="false">
						<a-row :gutter="[24, 24]">
							<a-col :span="12">
								<a-input v-decorator="[
									'captcha',
									{ rules: [{ required: true, message: 'Please input captcha!' }] },
								]" type="text" placeholder="验证码" />
							</a-col>
							<a-col :span="8">
								<img :src="captchaUrl" @click="replaceCaptcha" style="cursor: pointer;" />
							</a-col>
						</a-row>
					</a-form-item>
					<a-form-item class="mb-10">
						<a-switch v-model="rememberMe" /> 记住我
					</a-form-item>
					<a-form-item>
						<a-button :loading="loading" type="primary" block html-type="submit" class="login-form-button">
							登录
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

				<p class="font-semibold text-muted">没有账户? <router-link to="/sign-up"
						class="font-bold text-dark">注册</router-link></p>

				<a-divider>Or</a-divider>
				<a-row :gutter="[24, 24]">
					<a-col :span="6" :md="4"></a-col>
					<a-col hidden :span="2" :md="4">
						<a href="https://github.com/login/oauth/authorize?client_id=bb6528c044abb9e42aa4">
							<a-icon type="github" style="color: black; font-size: 1.5em;" />
						</a>
					</a-col>
					<a-col :span="2" :md="4">
						<a
							href="https://gitee.com/oauth/authorize?client_id=e7f027042ead934ff236f44e50b377ad96165039575618f0730aeb82cb204fb6&response_type=code&redirect_uri=https://qdu-soft.syhu.com.cn/api/platform/oauth/gitee">
							<svg t="1687338092937" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
								p-id="1582" width="21" height="21">
								<path
									d="M512 1024C229.222 1024 0 794.778 0 512S229.222 0 512 0s512 229.222 512 512-229.222 512-512 512z m259.149-568.883h-290.74a25.293 25.293 0 0 0-25.292 25.293l-0.026 63.206c0 13.952 11.315 25.293 25.267 25.293h177.024c13.978 0 25.293 11.315 25.293 25.267v12.646a75.853 75.853 0 0 1-75.853 75.853h-240.23a25.293 25.293 0 0 1-25.267-25.293V417.203a75.853 75.853 0 0 1 75.827-75.853h353.946a25.293 25.293 0 0 0 25.267-25.292l0.077-63.207a25.293 25.293 0 0 0-25.268-25.293H417.152a189.62 189.62 0 0 0-189.62 189.645V771.15c0 13.977 11.316 25.293 25.294 25.293h372.94a170.65 170.65 0 0 0 170.65-170.65V480.384a25.293 25.293 0 0 0-25.293-25.267z"
									fill="#C71D23" p-id="1583"></path>
							</svg>
						</a>
					</a-col>
					<a-col :span="6"></a-col>
				</a-row>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>

	</div>
</template>

<script>

export default ({
	data() {
		return {
			// Binded model property for "Sign In Form" switch button for "Remember Me" .
			rememberMe: true,
			loading: false,
			captchaUrl: `/api/platform/core/captcha?${new Date().getTime()}`,
		}
	},
	beforeCreate() {
		// Creates the form and adds to it component's "form" property.
		this.form = this.$form.createForm(this, { name: 'normal_login' });
	},
	methods: {
		// Handles input validation after submission.
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				console.log(values);
				if (!err) {
					this.loading = true

					values.rememberMe = this.rememberMe
					this.$axios.post(`/api/platform/user/login?captcha=${values.captcha}`, values)
						.then(res => {
							if (res.code === 0) {
								this.$store.commit('setToken', res.data.token)
								this.$axios.get('/api/platform/user/userInfo')
									.then(res => {
										if (res.code === 0) {
											this.$store.commit('setUserInfo', res.data)
										} else {
											this.$store.commit('clear')
										}
										this.loading = false
										this.$router.push('/')
									})
							} else {
								this.replaceCaptcha()
								this.$message.error(res.msg)
								this.loading = false
							}
						})
				}
			});
		},
		replaceCaptcha() {
			this.captchaUrl = `/api/platform/core/captcha?${new Date().getTime()}`
		},
	},
	mounted() {
		const token = this.$store.state.token
		const userInfo = this.$store.state.userInfo
		if (token && ('username' in userInfo)) {
			this.$axios.get('/api/platform/user/userInfo')
				.then(res => {
					if (res.code === 0) {
						this.$store.commit('setUserInfo', res.data)
						this.$store.commit('setToken', token)
						this.$router.push('/')
					} else {
						this.$store.commit('clear')
					}
				})
		}
	},
})

</script>

<style lang="scss">
body {
	background-color: #ffffff;
}

.a-col {
	min-height: 1px;
}
</style>